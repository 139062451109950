import React, { useState } from "react";
import Electric from "../../components/svgs/Electric";

import "./MainSection.css";

import Button from "../../components/ui/Button";
import auth from "../../services/authServices";
// import FlexRowReverse from "../../components/ui/FlexRowReverse";
import Container from "../../components/ui/Container";
// import NewProfile from "../../assets/Updated-imagery/New-Profile-hero.svg";
import BassthalkCoursesImg from "../../assets/Updated-imagery/bassthalkImg.png";
import starsbg from "../../assets/starsbg.png";
import bgbottomleft from "../../assets/bgbottomleft.svg";
import TeacherSlider from "./TeacherSlider";
// import Waleed from "../../components/svgs/Waleed";
// import YearsBooks from "../../components/svgs/YearsBooks";
// import Shapes from "../../components/svgs/Shapes";
// import Waves from "../../components/ui/Waves";
import pattern from "../../assets/Updated-imagery/pattern.svg";
const MainSection = ({ title = false }) => {
    const token = auth.getToken();

    // const buttonColorClass = token ? {} : { color: "rose" };

    return (
        <>
            <section className="md:h-screen min-h-screen relative overflow-hidden flex md:items-center md:justify-center md:mb-[10rem] home-main_section">
                <div
                    className="right-0 top-0 bottom-0 h-full w-full z-0 absolute opacity-20 dark:opacity-15  smooth"
                    style={{
                        backgroundImage: "url(" + pattern + ")",
                        backgroundSize: "contain",
                        backgroundPosition: "center center",
                        backgroundRepeat: "repeat-both",
                    }}
                ></div>
                <Container className="h-auto relative z-10  ">
                    <div className="w-full flex md:flex-row-reverse flex-col-reverse h-full justify-evenly md:h-screen space-y-20 space-y-reverse md:space-y-0 relative z-10 items-center">
                        <div className="md:basis-1/2 basis-full h-full flex-center-both">
                            <div className="w-full h-full max-w-2xl flex-center-both">
                                <img
                                    src={BassthalkCoursesImg}
                                    alt={"profile-updated-svg"}
                                    className="dark:opacity-90 smooth w-full"
                                />
                            </div>
                        </div>
                        <div className=" md:basis-1/2 basis-full h-full flex-center-both pt-20 md:pt-0">
                            <div className="flex-col space-y-6 md:space-y-5 text-center lg:text-right">
                                {/* <h2 className="font-h1 font-w-bold">منصة</h2> */}
                                <div className="flex justify-center lg:justify-start">
                                    <h1 className="font-w-bold smooth relative text-4xl md:text-5xl xl:text-6xl">
                                        <div className="relative z-10 clr-text-primary smooth ">
                                            <span className="text-3xl font-bold">كورسات</span>
                                            {"   "}
                                            <span className="text-dodgerBlue-500 vipFont ">
                                                بسطتهالك
                                            </span>
                                        </div>
                                    </h1>
                                </div>
                                <h2 className="font-h2 font-w-bold">
                                    كل المهارات اللي محتاجها لبناء مستقبلك
                                    <br />
                                    في متناول إيدك{" "}
                                </h2>
                                <div className="w-60 h-1 bg-dodgerBlue-300 dark:bg-sky-800 smooth mx-auto lg:mx-0"></div>
                                <div className="flex-col">
                                    <Button
                                        color="NewSky"
                                        className="text-lg font-bold md:text-xl px-[15px] py-[15px]  border-none inline-block scale smooth shadow-md hover:shadow-lg rounded-xl sm:rounded-xl  "
                                        element="Link"
                                        to={`${token ? "/me/user/courses" : "/register"}`}
                                    >
                                        {token ? (
                                            <span>كورساتي </span>
                                        ) : (
                                            <span className="text-primSky-100">
                                                ابدأ رحلتك {"  "}
                                                <span className="text-white">الأن</span>
                                            </span>
                                        )}
                                    </Button>
                                    {/* <div className="pb-6 pt-2 px-5 font-w-bold font-h1">____</div> */}
                                    {/* <Button
                                        color="purple"
                                        className="font-h2 font-bold px-10 py-4 sm:rounded-3xl  inline-block"
                                        element="a"
                                        href="https://mosalah-store.com/"
                                        target="_blank"
                                    >
                                        <span className="">اطلب الكتب من هنا !</span>
                                    </Button> */}
                                </div>
                                {title ? <div className="font-h2">{title}</div> : ""}
                            </div>
                        </div>
                    </div>
                    {/* <div className="w-full flex flex-col justify-center h-1/3">
                        <h2 className="font-h2 font-w-bold text-center">
                            <span>مُدرسين</span>{" "}
                            <span className="font-w-bold text-sky-500 bg-box relative">
                                بسطتهالك !
                            </span>
                        </h2>
                        <TeacherSlider />
                    </div> */}
                </Container>
            </section>
        </>
    );
};

export default MainSection;
