/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AdminContainer from "../../components/ui/AdminContainer";
import AdminForm from "../../components/ui/AdminForm";
import InputIcon from "../../components/form/elements/InputIcon";
import {
    handleCrud,
    handleFormSubmit,
    handleInputChange as handleChange,
    renderInputFields,
} from "../../services/formServices";
import auth from "../../services/authServices";
import http from "../../services/httpServices";

import { submitTypes } from "../../services/adminServices";

const initialState = {
    option_value: "",
    submit_type: 0,
    form_option_id: 0,
};

const FormOptionsEditing = () => {
    const [data, setData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [inputFields, setInputFields] = useState([]);

    const [formOptions, setFormOptions] = useState([]);

    const getFormOptions = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(`/api/form_options/options`, config);
        setFormOptions(response);
    };

    const getFormOptionInfo = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(
            `/api/form_options/${data.form_option_id}`,
            config
        );

        setData({ ...data, ...response });
    };

    useEffect(() => {
        if (data.submit_type && data.submit_type !== "store") {
            getFormOptions();
        }
    }, [data.submit_type]);

    useEffect(() => {
        if (data.form_option_id > 0 && data.submit_type && data.submit_type !== "store") {
            getFormOptionInfo();
        }
    }, [data.form_option_id, data.submit_type]);

    useEffect(() => {
        let fields = [
            {
                id: "submit_type",
                placeholder: "هتعمل ايه دلوقتي",
                type: "select",
                options: submitTypes,
            },
        ];

        if (data.submit_type) {
            if (data.submit_type && data.submit_type !== "store") {
                fields = [
                    ...fields,
                    {
                        id: "form_option_id",
                        placeholder: "اختر الوظيقة",
                        type: "select",
                        options: formOptions,
                    },
                ];
                if (data.submit_type === "update") {
                    if (data.form_option_id > 0) {
                        fields = [
                            ...fields,
                            {
                                id: "option_value",
                                placeholder: "الاسم",
                                icon: <InputIcon icon="fluent:app-title-24-filled" />,
                            },
                        ];
                    }
                }
            }

            if (data.submit_type && data.submit_type === "store") {
                fields = [
                    ...fields,
                    {
                        id: "option_value",
                        placeholder: "الاسم",
                        icon: <InputIcon icon="fluent:app-title-24-filled" />,
                    },
                ];
            }
        }
        setInputFields(fields);
    }, [data.submit_type, formOptions, data.form_option_id, data.submit_type]);

    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, () => {
            const adminToken = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(adminToken);
            const crudApiEndPoint = "/api/form_options";
            const crudItemName = "الوظيفة";

            handleCrud(
                config,
                data,
                crudApiEndPoint,
                crudItemName,
                setIsLoading,
                setErrors,
                setData,
                initialState,
                data.form_option_id
            );
        });
    };

    return (
        <AdminContainer>
            <AdminForm
                onSubmit={handleSubmit}
                isLoading={isLoading}
                buttonText="auto"
                submitType={data.submit_type}
            >
                {inputFields.map((input, key) =>
                    renderInputFields(
                        key,
                        input.handleChange ? input.handleChange : handleChange,
                        data,
                        setData,
                        errors,
                        input
                    )
                )}
            </AdminForm>
        </AdminContainer>
    );
};

export default FormOptionsEditing;
