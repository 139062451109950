import React from "react";

const ApplicantStatsCards = ({ extraData }) => {
    return (
        <div className="bg-primSky-950 dark:bg-sky-950 smooth shadow-md rounded p-6 mb-6 text-center">
            <h2 className="text-2xl font-bold mb-6">بيانات واحصائيات المقبولين</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <div className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth">
                    <h3 className="text-lg font-semibold">إجمالي الطلبات المقبولة</h3>
                    <span className="text-2xl font-bold">{extraData.total_approved}</span>
                </div>
                <div className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth">
                    <h3 className="text-lg font-semibold">إجمالي الطلبات المرفوضة</h3>
                    <span className="text-2xl font-bold">{extraData.total_declined}</span>
                </div>
                <div className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth">
                    <h3 className="text-lg font-semibold">عدد الذكور المقبولين</h3>
                    <span className="text-2xl font-bold">{extraData.male_accepted}</span>
                </div>
                <div className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth">
                    <h3 className="text-lg font-semibold">عدد الإناث المقبولات</h3>
                    <span className="text-2xl font-bold">{extraData.female_accepted}</span>
                </div>
            </div>
            <div className="mt-6">
                <h3 className="text-xl font-semibold mb-4">توزيع المقاعد:</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <div className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth">
                        <h3 className="text-lg font-semibold">مقاعد الذكور</h3>
                        <span className="text-2xl font-bold">
                            {extraData.seats_info.male_seats.toFixed(0)}
                        </span>
                    </div>
                    <div className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth">
                        <h3 className="text-lg font-semibold">مقاعد الإناث</h3>
                        <span className="text-2xl font-bold">
                            {extraData.seats_info.female_seats.toFixed(0)}
                        </span>
                    </div>
                    <div className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth">
                        <h3 className="text-lg font-semibold">مقاعد الانتظار للذكور</h3>
                        <span className="text-2xl font-bold">
                            {extraData.seats_info.male_extra_seats.toFixed(0)}
                        </span>
                    </div>
                    <div className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth">
                        <h3 className="text-lg font-semibold">مقاعد الانتظار للإناث</h3>
                        <span className="text-2xl font-bold">
                            {extraData.seats_info.female_extra_seats.toFixed(0)}
                        </span>
                    </div>
                </div>
                <h3 className="text-xl font-semibold mt-6 mb-4">
                    عدد الطلبات قيد المراجعة والكراسي المتبقية:
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                    <div className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth">
                        <h3 className="text-lg font-semibold">عدد الطلبات قيد المراجعة للذكور</h3>
                        <span className="text-2xl font-bold">{extraData.male_pending}</span>
                    </div>
                    <div className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth">
                        <h3 className="text-lg font-semibold">عدد الطلبات قيد المراجعة للإناث</h3>
                        <span className="text-2xl font-bold">{extraData.female_pending}</span>
                    </div>
                    <div className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth">
                        <h3 className="text-lg font-semibold">عدد الكراسي المتبقية للذكور</h3>
                        <span className="text-2xl font-bold">
                            {extraData.remaining_male_seats.toFixed(0)}
                        </span>
                    </div>
                    <div className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth">
                        <h3 className="text-lg font-semibold">عدد الكراسي المتبقية لللإناث</h3>
                        <span className="text-2xl font-bold">
                            {extraData.remaining_female_seats.toFixed(0)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApplicantStatsCards;
