import "./teacher.css";
import { motion, useMotionValue, useSpring, useTransform } from "framer-motion";
import React, { useState, useRef, useEffect } from "react";

function ParallaxTeacher({ children, baseVelocity = 0, moveX }) {
    const baseX = useMotionValue(0);
    const springX = useSpring(baseX, { damping: 20, stiffness: 200 });
    const [motionDivWidth, setMotionDivWidth] = useState(0);
    const motionDivRef = useRef(null);

    const numChildren = React.Children.count(children);
    const middleIndex = Math.floor(numChildren / 2);

    // Calculate the total width of the motion div and center the middle child
    useEffect(() => {
        if (motionDivRef.current) {
            setMotionDivWidth(motionDivRef.current.offsetWidth);
            // Center the middle child
            const childWidth = motionDivRef.current.scrollWidth / numChildren;
            const offset = (middleIndex - 0.5) * childWidth;
            baseX.set(-offset);
        }
    }, [children, numChildren, middleIndex, baseX]);

    // Convert moveX from pixels to percentage based on motionDivWidth
    const moveXPercentage = motionDivWidth > 0 ? (moveX / motionDivWidth) * 100 : 0;

    const x = useTransform(springX, (v) => `${v + moveXPercentage}%`);

    useEffect(() => {
        springX.set(moveXPercentage);
    }, [moveXPercentage, springX]);

    return (
        <div className="parallaxTeacher">
            <motion.div className="scroller" style={{ x }} ref={motionDivRef}>
                {children}
            </motion.div>
        </div>
    );
}

export default ParallaxTeacher;
