import "./teacher.css";
import ParallaxTeacher from "./Parallax";
import React, { useEffect, useState, useRef } from "react";
import auth from "../../../services/authServices";
import http from "../../../services/httpServices";
import { description as FormatDesc } from "../../../utils/ar";
import { Link } from "react-router-dom";
import LoadingIcon from "../../ui/LoadingIcon";
import Arrow from "../../../assets/Updated-imagery/arrow.png";
import { baseURL } from "../../../config";

export default function TeacherLine({ department_id = 0, subjectId = 0, isSubject = false }) {
    const offset = 50;
    const [moveX, setMoveX] = useState(0); // Start with offset
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isHovering, setIsHovering] = useState(false);
    const [cardWidth, setCardWidth] = useState(300);
    const [maxMoveX, setMaxMoveX] = useState(0);

    const [teachers, setTeachers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const cardRef = useRef(null);
    const containerRef = useRef(null);
    console.log("moveX", moveX);
    console.log("maxMoveX", maxMoveX);
    const getTeachers = async () => {
        setIsLoading(true);
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);

        let result;
        if (isSubject) {
            result = await http.get(`api/subjects/${subjectId}/teachers`, config);
        } else {
            result = await http.get(`api/departments/${department_id}/teachers`, config);
        }

        const { data } = result;
        setTeachers(data);
        setIsLoading(false);
    };

    useEffect(() => {
        if (department_id > 0 || subjectId > 0) {
            getTeachers();
        }
    }, [department_id, subjectId]);

    useEffect(() => {
        if (cardRef.current && containerRef.current) {
            const cardWidth = cardRef.current.offsetWidth;
            const containerWidth = containerRef.current.offsetWidth;
            setCardWidth(cardWidth);

            const totalWidth = cardWidth * teachers.length;
            setMaxMoveX(Math.max(0, totalWidth - containerWidth + offset));
        }
    }, [teachers]);

    const handleCarouselLeft = () => {
        if (currentIndex > 0) {
            const newIndex = currentIndex - 1;
            const newMoveX = Math.max(-offset, moveX - cardWidth);
            setCurrentIndex(newIndex);
            setMoveX(newMoveX);
        }
    };

    const handleCarouselRight = () => {
        if (currentIndex < teachers.length - 1) {
            const newIndex = currentIndex + 1;
            const newMoveX = Math.min(maxMoveX, moveX + cardWidth);
            setCurrentIndex(newIndex);
            setMoveX(newMoveX);
        }
    };

    const handleHover = () => {
        setIsHovering(!isHovering);
    };

    return (
        <section className="ScrollLine overflow-hidden">
            {isLoading ? (
                <div className="flex-center-both">
                    <div className="border-2 rounded-md border-dodgerBlue-300 dark:border-dodgerBlue-800 p-10 bg-dodgerBlue-100 dark:bg-opacity-5 smooth clr-text-primary flex space-x-2 space-x-reverse">
                        <LoadingIcon className="font-h1 text-dodgerBlue-500" />
                        <span>يتم الآن تحميل المٌحاضرين المٌخصصين لك ...</span>
                    </div>
                </div>
            ) : teachers.length < 1 ? (
                <div className="flex-center-both">
                    <div className="border-2 rounded-md border-rose-300 dark:border-rose-500 p-10 bg-rose-400 dark:bg-opacity-5 smooth clr-text-primary">
                        سيتم اضافة مٌحاضرين مٌتخصصين لك قريبًا
                    </div>
                </div>
            ) : (
                <div className="flex flex-col md:flex-row-reverse space-y-5 items-center space-x-0 lg:space-x-5 justify-center">
                    <button
                        onClick={handleCarouselLeft}
                        className="px-4 py-2 rounded-md"
                        // Disable left arrow when at the end
                        disabled={currentIndex === 0}
                    >
                        <img
                            src={Arrow}
                            alt="LeftArrow"
                            className={`w-[48px] md:w-[80px] rotate-180 ${
                                currentIndex === 0 ? "opacity-50" : ""
                            }`}
                        />
                    </button>
                    <div
                        ref={containerRef}
                        className="firstLineTeacher overflow-hidden w-full md:max-w-[80%] mx-auto relative bg-dodgerBlue-100 dark:bg-slate-800 smooth"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                    >
                        <ParallaxTeacher baseVelocity={isHovering ? 0 : -5} moveX={moveX}>
                            <div className="lineTeacher">
                                {teachers.map((teacher, index) => (
                                    <Link to={`/teacher/${teacher.id}`} key={index}>
                                        <div
                                            ref={index === currentIndex ? cardRef : null}
                                            className="p-6 bg-dodgerBlue-200 dark:bg-slate-800 border border-dodgerBlue-700 smooth"
                                        >
                                            <div
                                                className="img-container rounded-[11px] bg-dodgerBlue-400 shadow-lg"
                                                key={index}
                                            >
                                                <img
                                                    className="image p-1"
                                                    src={`${baseURL}/${teacher.picture}`}
                                                    alt="Teacher"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex clr-text-primary smooth flex-col space-y-2">
                                            <span className="text-center pt-4 text-xl font-rubik font-w-bold">
                                                {teacher.name}
                                            </span>
                                            <span className="text-center max-w-[200px] mx-auto pb-7 text-xl text-wrap">
                                                {FormatDesc(teacher.description, true)}
                                            </span>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </ParallaxTeacher>
                    </div>
                    <button
                        onClick={handleCarouselRight}
                        className="px-4 py-2 rounded-md"
                        disabled={moveX >= maxMoveX}
                    >
                        <img
                            src={Arrow}
                            alt="RightArrow"
                            className={`w-[48px] md:w-[80px] ${
                                moveX >= maxMoveX ? "opacity-50" : ""
                            }`}
                        />
                    </button>
                </div>
            )}
        </section>
    );
}
