import React, { useContext, useEffect, useState } from "react";

import ScrollingContext from "../../context/ScrollingContext";

import { getScrollingPercentage, windowHeight } from "../../services/scrollingServices";

import Waves from "./Waves";

import "./sectionHead.css";

const SectionHead = ({ className = "bg-dodgerBlue-500 text-slate-100", title = "الكورسات" }) => {
    const [percentage, setPercentage] = useState(0);
    const { scrolling } = useContext(ScrollingContext);

    useEffect(() => {
        const yearsHeader = document.querySelector(".home-years__head");

        let { percentage: percent } = getScrollingPercentage(
            scrolling,
            yearsHeader.getBoundingClientRect().top + scrolling,
            (windowHeight + yearsHeader.style.height) / 2
        );
        setPercentage(percent);
    }, [scrolling]);

    return (
        <>
            <div className={`home-years__head ${className}`}>
                <Waves className="fill-primary-container  transform  rotate-180    " />

                {/* <div className="relative px-10 overflow-hidden">
                    <div className="home-years__title font-big font-w-bold relative">
                        <div
                            className="home-years-title-bg__container"
                            style={{ transform: `translateX(${-100 * (1 - percentage)}%)` }}
                        >
                            <div className="home-years-title__bg font-bigmax text-slate-50">
                                {title}
                            </div>
                        </div>

                        <div className="home-years-title__content relative z-10 text-slate-50">
                            {title}
                        </div>
                    </div>
                </div> */}

                <Waves className="fill-primary-container transform   -mt-10" />
                {/* <Waves className="fill-primary-container" /> */}
            </div>
            {/* <svg width="100%" height="auto" xmlns="http://www.w3.org/2000/svg">
                <path className="fill-sky-500" d="M0 0h3000v100H0z" />
                <path
                    d="m0 56 38.5-4.5C77 47 154 38 231 44s154 27 230.8 26c76.9-1 153.5-24 230.4-32.2C769 29.7 846 36.3 923 47.7c77 11.3 154 27.3 231 33.6 77 6.4 154 3 230.8-6.8 76.9-9.8 153.5-26.2 230.4-32.5 76.8-6.3 153.8-2.7 230.8-1.7 77 1 154-.6 231 2.4s154 10.6 230.8 18c76.9 7.3 153.5 14.3 230.4 4.6 76.8-9.6 153.8-36 230.8-40.8 77-4.8 154 11.8 192.5 20.2L3000 53v48H0Z"
                    className="fill-primary-container smooth"
                />
            </svg> */}
        </>
    );
};

export default SectionHead;
