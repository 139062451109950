import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    getFormData,
    handleFormErrors,
    handleFormSubmit,
    handleInputChange,
} from "../services/formServices";
import http from "../services/httpServices";
import page from "../services/pageServices";

import InputField from "../components/form/elements/InputField";
import InputIcon from "../components/form/elements/InputIcon";
import SigningForm from "../components/form/singingForm/SigningForm";

import loginPicture from "../assets/join-us.png";
import modal from "../services/modalServices";

import loginPageIcon from "../assets/job.svg";

const JoinUsHome = () => {
    const [user, setUser] = useState({
        first_name: "",
        second_name: "",
        third_name: "",
        last_name: "",
        phone: "",
        email: "",
        national_id_copy: "",
        resume: "",
        expected_salary: "",
        what_value: "",
        candidate_type: "",
        address: "",
        government_id: "",
    });

    const [errors, setErrors] = useState({});

    const [isLoading, setIsLoading] = useState(false);

    const [candidateTypes, setCandidateTypes] = useState([]);
    const [governments, setGovernments] = useState([]);

    const getGovernments = async () => {
        try {
            const { data: response } = await http.get("api/governments");
            setGovernments(response);
        } catch (error) {
            // console.log(error);
        }
    };

    const getCadidateTypes = async () => {
        try {
            const { data: response } = await http.get("api/candidate_types");
            setCandidateTypes(response);
        } catch (error) {
            // console.log(error);
        }
    };

    useEffect(() => {
        getCadidateTypes();
        getGovernments();
    }, []);

    const inputFields = [
        {
            id: "candidate_type",
            placeholder: "الوظيفة",
            type: "select",
            options: candidateTypes,
            icon: <InputIcon icon="ant-design:usergroup-add-filled" />,
            className: "lg:col-span-2",
        },
        {
            id: "first_name",
            placeholder: "الإسم الأول",
            icon: <InputIcon icon="icon-park-solid:edit-name" />,
        },
        {
            id: "second_name",
            placeholder: "الإسم الثاني",
            icon: <InputIcon icon="icon-park-solid:edit-name" />,
        },
        {
            id: "third_name",
            placeholder: "الإسم الثالث",
            icon: <InputIcon icon="icon-park-solid:edit-name" />,
        },
        {
            id: "last_name",
            placeholder: "الإسم الأخير",
            icon: <InputIcon icon="icon-park-solid:edit-name" />,
        },
        {
            id: "phone",
            placeholder: "رقم الهاتف",
            icon: <InputIcon icon="ant-design:phone-filled" />,
        },
        {
            id: "expected_salary",
            placeholder: "الراتب المتوقع",
            icon: <InputIcon icon="ant-design:dollar-circle-filled" />,
        },
        {
            id: "what_value",
            type: "textarea",
            placeholder: "ايه اللي هتضيفه لبستطهالك",
            icon: <InputIcon icon="ant-design:bulb-filled" />,
            className: "lg:col-span-2",
        },
        {
            id: "government_id",
            placeholder: "المحافظة",
            type: "select",
            options: governments,
            className: "lg:col-span-2",
        },

        {
            id: "address",
            placeholder: "العنوان",
            icon: <InputIcon icon="ant-design:home-filled" />,
        },
        {
            id: "email",
            placeholder: "البريد الإلكتروني",
            icon: <InputIcon icon="ant-design:mail-filled" />,
        },
        {
            id: "national_id_copy",
            placeholder: "صورة البطاقة الشخصية",
            type: "file",
        },
        {
            id: "resume",
            placeholder: "السيرة الذاتية / البورتفوليو",
            type: "file",
        },
        {
            id: "facebook_link",
            placeholder: "رابط الفيسبوك (اختياري)",
            icon: <InputIcon icon="ant-design:facebook-filled" />,
            isRequired: false,
        },
        {
            id: "instagram_link",
            placeholder: "رابط الإنستجرام (اختياري)",
            icon: <InputIcon icon="ant-design:instagram-filled" />,
            isRequired: false,
        },
        {
            id: "other_social_media",
            placeholder: "رابط موقعك الشخصي / بورتفوليو / بيهانس او غيره (اختياري)",
            icon: <InputIcon icon="bytesize:portfolio" />,
            isRequired: false,
            className: "lg:col-span-2",
        },
    ];

    useEffect(() => {
        const currentTitle = page.getCurrentTitle();
        page.setTitle("انضم إلى فريق بستطهالك");
        return () => {
            page.setTitle(currentTitle);
        };
    }, []);

    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, submitFormResponse);
    };

    const navigate = useNavigate();

    const redirect = (certainPath = false) => {
        navigate("/home");
    };

    const submitFormResponse = async () => {
        try {
            await http.get("/sanctum/csrf-cookie");
            let toSendUser = { ...user };
            toSendUser["phone"] = parseInt(user["phone"]);
            console.log(toSendUser);
            toSendUser = getFormData(toSendUser);
            console.log(toSendUser);
            await http.post("api/form_responses", toSendUser);
            modal.message({
                title: "تم انشاء حسابك و تسجيل الدخول بنجاح !",
                text: "اضغط حسنًا للإستمرار",
                callback: redirect,
            });
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors, true);
        }
    };

    return (
        <>
            <SigningForm
                headTitle={"انضم إلى فريق بسطتهالك !"}
                headIcon={loginPageIcon}
                picture={loginPicture}
                description={"سجل بياناتك الآن للإنضمام إلى فريق بسطتهالك !"}
                onSubmit={handleSubmit}
                fields={
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-6">
                        {inputFields.map((input, index) => {
                            return (
                                <InputField
                                    key={index}
                                    onChange={handleInputChange}
                                    data={user}
                                    setData={setUser}
                                    errors={errors}
                                    {...input}
                                />
                            );
                        })}
                    </div>
                }
                color={"cyan"}
                secondaryColor={"cyan"}
                buttonTitle="انضم الآن"
                isLoading={isLoading}
            />
        </>
    );
};

export default JoinUsHome;
