import React, { useEffect, useState } from "react";
import InputField from "../../components/form/elements/InputField";
import AdminContainer from "../../components/ui/AdminContainer";
import RemoteTable from "../../components/ui/RemoteTable";
import { printFullDateTime } from "../../utils/time";
import { handleInputChange } from "../../services/formServices";
import http from "../../services/httpServices";
import Button from "../../components/ui/Button";
import modal from "../../services/modalServices";
import { baseURL } from "../../config";
import { printGovernment } from "../../utils/ar";

const FormResponses = () => {
    const [governments, setGovernments] = useState([]);

    let columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => row.id,
            sortable: true,
            sortField: "id",
        },
        {
            name: "نوع الوظيفة",
            reorder: true,
            selector: (row) => row.candidate_type,
            sortable: true,
            sortField: "candidate_type",
        },
        {
            name: "القيمة المضافة",
            reorder: true,
            selector: (row) => row.what_value,
            sortable: true,
            sortField: "what_value",
        },
        {
            name: "الاسم الكامل",
            reorder: true,
            selector: (row) => row.full_name,
            sortable: true,
            sortField: "full_name",
        },
        {
            name: "رقم الهاتف",
            reorder: true,
            selector: (row) => row.phone,
            sortable: true,
            sortField: "phone",
        },
        {
            name: "البريد الإلكتروني",
            reorder: true,
            selector: (row) => row.email,
            sortable: true,
            sortField: "email",
        },
        {
            name: "اثبات شخصية",
            reorder: true,
            selector: (row) => (
                <Button
                    color="blue"
                    onClick={(e) => {
                        e.preventDefault();
                        modal.message({ icon: `${baseURL}/${row.national_id_copy}` });
                    }}
                    className=""
                >
                    عرض الصورة
                </Button>
            ),
            sortable: true,
            sortField: "national_id_copy",
        },
        {
            name: "السيرة الذاتية",
            reorder: true,
            selector: (row) => (
                <Button
                    color="blue"
                    element="a"
                    href={`${baseURL}/${row.national_id_copy}`}
                    target="_blank"
                    rel="noreferrer"
                    className="inline-block"
                >
                    عرض
                </Button>
            ),
            sortable: true,
            sortField: "national_id_copy",
        },
        {
            name: "المحافظة",
            reorder: true,
            selector: (row) => printGovernment(governments, row.government_id),
            sortable: true,
            sortField: "government_id",
        },
        {
            name: "العنوان",
            reorder: true,
            selector: (row) => row.address,
            sortable: true,
            sortField: "address",
        },
        {
            name: "رابط الفيسبوك",
            reorder: true,
            selector: (row) =>
                row.facebook_link ? (
                    <a
                        className="underline"
                        href={row.facebook_link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {row.facebook_link}
                    </a>
                ) : (
                    ""
                ),
            sortable: true,
            sortField: "facebook_link",
        },
        {
            name: "رابط الانستجرام",
            reorder: true,
            selector: (row) =>
                row.instagram_link ? (
                    <a
                        className="underline"
                        href={row.instagram_link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {row.instagram_link}
                    </a>
                ) : (
                    ""
                ),
            sortable: true,
            sortField: "instagram_link",
        },
        {
            name: "رابط مواقع اخرى",
            reorder: true,
            selector: (row) =>
                row.other_social_media ? (
                    <a
                        className="underline"
                        href={row.other_social_media}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {row.other_social_media}
                    </a>
                ) : (
                    ""
                ),
            sortable: true,
            sortField: "other_social_media",
        },
        {
            name: "الراتب المتوقع",
            reorder: true,
            selector: (row) => row.expected_salary,
            sortable: true,
            sortField: "expected_salary",
        },
    ];
    columns = [
        ...columns,
        {
            name: "تاريخ انشاء الطلب",
            reorder: true,
            selector: (row) => row.created_at,
            format: (row) => printFullDateTime(row.created_at),
            sortable: true,
            sortField: "created_at",
        },
    ];

    const initialState = {
        phone: "",
        full_name: "",
        candidate_type: "",
        government_id: 0,
    };

    const [filterData, setFilterData] = useState(initialState);

    const [data, setData] = useState(initialState);

    async function getGovernments() {
        const { data } = await http.get("/api/governments");
        setGovernments(data);
    }
    useEffect(() => {
        getGovernments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AdminContainer>
            <div className="w-full space-y-6">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6 w-full">
                    <InputField
                        id="phone"
                        placeholder="رقم الهاتف"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="full_name"
                        placeholder="الاسم"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="candidate_type"
                        placeholder="الوظيفة"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                        className="lg:col-span-2"
                    />
                    <InputField
                        id="government_id"
                        placeholder="المحافظة"
                        type="select"
                        options={[
                            {
                                value: 0,
                                text: "الكل",
                            },
                            ...governments,
                        ]}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                        className="lg:col-span-2"
                    />
                </div>
                <Button
                    className="w-full"
                    color="blue"
                    onClick={() => {
                        setFilterData(data);
                    }}
                >
                    بحث
                </Button>
                <RemoteTable
                    api={`/api/form_responses/paginate`}
                    columns={columns}
                    filterData={filterData}
                />
            </div>
        </AdminContainer>
    );
};

export default FormResponses;
