import React, { useContext, useState } from "react";
import {
    English,
    Arabic,
    France,
    italy,
    germany,
    Math,
    Geo,
    Bio,
    chemistry,
    Brain,
    book,
} from "./Svgs";
import { Link } from "react-router-dom";
import Button from "../../ui/Button";
import AuthContext from "../../../context/AuthContext";
import Pattern from "../../../assets/Updated-imagery/subjectsPattern.svg";
import LoadingIcon from "../../ui/LoadingIcon";
function SubjectCard({ first, sec, third }) {
    // console.log(first);

    const { token, user } = useContext(AuthContext);
    return (
        <div className="w-full h-full flex items-center justify-center">
            <div className="flex items-start justify-center flex-wrap h-full w-full gap-5 md:gap-20 lg:gap-10 xl:gap-5">
                {third.length < 1 ? (
                    <div className="flex-center-both">
                        <div className="border-2 rounded-md border-dodgerBlue-300 dark:border-dodgerBlue-500 p-10 bg-dodgerBlue-100 dark:bg-opacity-5 smooth clr-text-primary">
                            سيتم اضافة التخصصات المطلوبة قريبًا
                        </div>
                    </div>
                ) : (
                    <>
                        {third.map((item, index) => {
                            return (
                                <Link to={`/subject/${item[1]}`} key={index}>
                                    <div className="w-[330px] relative z-10 h-[250px] m-auto sm:min-w-[400px] hover:bg-[#102542] smooth px-3 py-6 rounded-3xl group bg-dodgerBlue-200 dark:bg-dodgerBlue-500 p-3 flex gap-2 flex-col items-center justify-evenly">
                                        <p
                                            className="relative z-10 text-[2.5rem] smooth  rounded-2xl px-5 py-2  font-bold text-center group-hover:text-dodgerBlue-50 text-dodgerBlue-950"
                                            key={index}
                                        >
                                            {item[0]}
                                        </p>
                                        <div className="flex items-center absolute z-0 justify-center gap-2">
                                            <img
                                                src={Pattern}
                                                alt="pattern"
                                                className=" max-w-[250px] opacity-25"
                                            />
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </>
                )}
            </div>
        </div>
    );
}

export default SubjectCard;
