import React from "react";

import logo from "../assets/navbar/logo.png";
//Router component
import NavbarLink from "../layout/navbar/components/NavbarLink";
//menu
import DisclosureLink from "../layout/navbar/components/DisclosureLink";
import CenterIcon from "../components/ui/CenterIcon";
import ideas from "../assets/Updated-imagery/ideas.png";
import video from "../assets/Updated-imagery/video.png";

// logo png is here
export const navLogoComponent = (darkmode) => (
    <>
        {/* <img
            className="h-12 w-auto block lg:hidden"
            src={logo}
            alt="Workflow"
        /> */}
        <img className="h-10 sm:h-14 w-auto md:mr-0 mr-6" src={logo} alt="Workflow" />
    </>
);
export const navbarClassName = (isNavbarHidden, isRTL, scrolling) =>
    // color of nav background
    ` ${!isRTL ? "en" : ""} ${scrolling && "bg-primary-container"}`;

// here are Buttons
export const NavbarRegisterComponent = () => (
    <NavbarLink
        defualtHover="hover:bg-primary-container hover:text-dodgerBlue-500 group"
        to="/register"
        className="bg-dodgerBlue-500 clr-white border-2 border-dodgerBlue-500 group"
    >
        <img src={video} alt="video" className="w-8" />
        <span className="flex-center-both">اعمل حساب جديد !</span>
    </NavbarLink>
);

export const NavbarLoginComponent = ({ isRTL }) => (
    <NavbarLink
        to="/login"
        className="border border-yellow-400 border-opacity-0 text-yellow-500"
        defualtHover="hover:dark:border-opacity-100  hover-shadow"
    >
        {/* <span className="dark:text-yellow-400 text-yellow-600 flex-center-both font-h2">
            <CenterIcon icon={"ic:twotone-accessibility-new"} />
        </span> */}
        <img src={ideas} alt="ideas" className="w-8" />
        <span
            className={`flex-center-both space-x-1 space-x-reverse ${
                !isRTL ? "flex-row-reverse" : ""
            }`}
        >
            <span>سجل</span> <span className="dark:text-orange-400 text-orange-600">دخولك</span>
        </span>
    </NavbarLink>
);

export const DisclosureLoginComponent = ({ isRTL }) => (
    <DisclosureLink to="/login">
        <span className="text-yellow-500 flex-center-both font-h2">
            <CenterIcon icon={"ic:twotone-accessibility-new"} />
        </span>
        <span className="flex-center-both">سجل الدخول لـ حسابك</span>
    </DisclosureLink>
);
export const DisclosureRegisterComponent = () => (
    <DisclosureLink to="/register">
        <span className="flex-center-both font-h2 smooth text-sky-400 group-hover:text-sky-500 dark:group-hover:text-sky-500">
            <CenterIcon icon="material-symbols:tab-new-right-outline-rounded" />
        </span>
        {/* <UserAddIcon className="h-6 w-6 text-yellow-300" /> */}
        <span className="flex-center-both">اعمل حساب جديد !</span>
    </DisclosureLink>
);

export const domainName = "mahmoud-magdy.com";
