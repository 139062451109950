import React from "react";

const SideTitle = ({ first, last, className = "" }) => {
    if (!className.includes("font-")) {
        className += ` font-big`;
    }
    return (
        <div className={`font-w-bold clr-text-primary ${className}`}>
            <div className="relative">
                <span className="">
                    <span className="clr-text-primary smooth">{first} </span>
                    <span className="text-dodgerBlue-500 dark:text-dodgerBlue-600 smooth">
                        {last}{" "}
                    </span>
                </span>
                <span className="absolute w-28 h-1 bg-secondary-container rounded-md right-0 top-full smooth"></span>
                <span className="absolute w-14 h-1 bg-secondary-container rounded-md right-0 top-full transform translate-y-2 smooth"></span>
                <span className="absolute w-14 h-1 bg-secondary-container rounded-md right-0 bottom-full transform -translate-y-2 smooth"></span>
            </div>
        </div>
    );
};

export default SideTitle;
