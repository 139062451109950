// import React from 'react'
import "./card.css";
import Cv from "../../../assets/Updated-imagery/cv.png";
import coding from "../../../assets/Updated-imagery/coding.png";
import camera from "../../../assets/Updated-imagery/camera.png";
const Card = () => {
    let content = [
        {
            header: "هـتشارك",
            desc: "مجموعات للمناقشة عشان تسأل وتشارك أفكارك مع زمايلك",
            img: camera,
        },
        {
            header: "هـنجهزك",
            desc: "هنجهزك للمنافسة في سوق العمل عشان تضمن ليك مكان في السوق",
            img: Cv,
        },
        {
            header: "هتنافس",
            desc: " هنخليك تنافس زمايلك في التخصصات اللي تحبها وتحب تشتغل فيها",
            img: coding,
        },
    ];
    return (
        <div className="w-full h-full flex flex-wrap justify-center gap-10">
            {content.map((item, index) => (
                <div key={index} className="flex justify-between h-full items-center flex-col">
                    <div className="h-[360px] relative rounded-md flex justify-center gap-5 flex-col items-center px-2 py-5">
                        <img
                            src={item.img}
                            alt={`img_${index}`}
                            className="w-[200px] h-auto mb-5 absolute top-0 -z-10"
                        />
                        <div className="absolute bottom-0 top-1/2 left-0 right-0 -z-10 bg-dodgerBlue-100/80 rounded-lg shadow-lg dark:bg-dodgerBlue-800/80 smooth" />
                        <div className="heading relative mt-6">
                            <h1 className="header-card shadow-md bg-dodgerBlue-100/70 rounded-lg dark:bg-dodgerBlue-800/70 py-2 px-2 text-dodgerBlue-950 dark:text-dodgerBlue-50  smooth relative z-10">
                                {item.header}
                            </h1>
                        </div>
                        <div className="desc mt-10">
                            <p className="text-dodgerBlue-950 text-lg pt-10 dark:text-dodgerBlue-50  smooth">
                                {item.desc}
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Card;
